import { AppBar, Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
//import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

export default function Login() {
  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');
  // const [error, setError] = useState('');
  // const navigate = useNavigate();

  // const handleSubmit = async (event: React.FormEvent) => {
  //   event.preventDefault();

  //   if (isNaN(Number(username))) {
  //     try {
  //       await authenticate(username, password);
  //       navigate('./app');
  //     } catch {
  //       setError('Invalid username or password');
  //     }
  //   } else {

  //     alert('Username cannot be a number');
  //   }

  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "10px",

        borderShadow: 3,
        marginTop: "30px",
        marginBottom: "30px",
        Width: "100%",
        padding: "30px",
        height: "95vh",
        backgroundColor: "white",
      }}
    >
      <Box>
        {" "}
        <AppBar style={{ backgroundColor: "white", boxShadow: "none" }}>
          <Avatar
            variant="square"
            alt="Logo"
            src={process.env.PUBLIC_URL + "/image/Navigo-logo.png"}
            sx={{ width: 210, height: 40, padding: 1, marginTop: 1 }}
          />
        </AppBar>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          padding: "1px",
          paddiingtop: "3px",
          px: 3,
          py: 3,
          gap: "20px",
        }}
      >
        <Box>
          <Typography component="h1" variant="h4">
            POWERED BY CREATEORS AROUND THE WORLD
          </Typography>
          <br />
          <br />
          <Typography
            component="p"
            sx={{
              color: "grey",
              fontSize: "14px",
            }}
          >
            don't have an account ?
          </Typography>
          <Typography
            component="p"
            sx={{
              color: "grey",
              fontSize: "14px",
            }}
          >
            Create Account{" "}
          </Typography>
          <Grid
            item
            xs
            sx={{
              color: "black",
            }}
          ></Grid>
        </Box>
        <Box
          sx={{
            borderRadius: "15px",
            backgroundImage:
              "url(https://source.unsplash.com/white-and-black-truck-near-mountain-at-daytime-lq9PxpwDZUk)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "200px",
            width: "80vh",
            display: "flex",
            flexDirection: "",
            justifyContent: "space-between",
            padding: "10px",
            gap: "10px",
          }}
        >
          <Box>
            <Typography sx={{ color: "black", fontSize: "large" }}>
              about Us
            </Typography>
            <Typography sx={{ color: "" }}>
              {" "}
              The success of every weong your competitors in terms of online
              sales.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: 4,
          py: 2,
          backgroundImage:
            "url(https://source.unsplash.com/white-and-black-truck-near-mountain-at-daytime-lq9PxpwDZUk)",
          backgroundSize: "cover",
          backgroundPosition: "center",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "90vh",
          borderRadius: "15px",
          width: "900px",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 4,
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "40vh",
            maxHeight: "60vh",

            backgroundColor: "white",
            borderRadius: "15px",
          }}
        >
          <Typography component="h1" variant="h5">
            Login to Your Account
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="User Name"
              // value={username}
              // onChange={(e) => setUsername(e.target.value)}
              type="text"
              id="password"
              variant="standard"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              // onChange={(e) => setPassword(e.target.value)}
              id="password"
              variant="standard"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Grid item xs></Grid>

            <Button
              variant="contained"
              fullWidth
              sx={{
                mt: 3,
                mb: 2,

                color: "white",
                backgroundColor: "black",
                borderRadius: "5",
              }}
              component={RouterLink}
              to="../auth/dashboard"
            >
              Login
            </Button>

            <Grid
              container
              sx={{
                px: 4,
                py: 3,
                backgroundImage:
                  "url(https://source.unsplash.com/white-and-black-truck-near-mountain-at-daytime-lq9PxpwDZUk)",
                backgroundSize: "cover",
                backgroundPosition: "center",

                borderRadius: "8px",
              }}
            ></Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

function authenticate(username: string, password: string) {
  throw new Error("Function not implemented.");
}
