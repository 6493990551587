
import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField, Typography } from "@mui/material";
import axios, { AxiosResponse } from 'axios';
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { User } from "./regions";


interface TestAPIResponse {
    success: string;
}
const breadcrumbs = [
    <Typography key="1" color="inherit">
        Enterprise Architecture
    </Typography>,
    <Typography key="2" color="text.primary">
        Workshop Group
    </Typography>,
];
const Addregions: React.FC = () => {

        
    const [Regionname, setRegionname] = useState("");
    const [RegionnameError, setRegionnameError] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setRegionnameError(false);
      

        if (Regionname === "") {
            setRegionnameError(true);
        }
       

        if (Regionname ) {
         
            const existingData: { suboems?: User[] } = JSON.parse(localStorage.getItem('users') || '{"suboems": []}');

            const userVar: User = {
                id: (existingData.suboems?.length || 0) + 1,
                regionname: Regionname,
            
            };

          
            existingData.suboems = existingData.suboems || [];
            existingData.suboems.push(userVar);

           
            localStorage.setItem('users', JSON.stringify(existingData));

            axios.post<TestAPIResponse>("http://localhost:3000/auth/suboem/addsuboem", userVar).then(
                (resp: AxiosResponse<TestAPIResponse>) => {
                    let d: TestAPIResponse = resp.data;
                    console.log("RESP: ", d);
                }
            );

       
            setRegionname("");
            
        }
    };

    return (
        <Box
            display="grid"
            justifyContent="center"
            alignItems="center"
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
                  <FormControl>
                <Button variant="outlined"
                  component = {RouterLink} to= "../">
                    Back
                </Button>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="username"
                    label="Region Name"
                    variant="outlined"
                    onChange={e => setRegionname(e.target.value)}
                    value={Regionname}
                    error={RegionnameError}
                ></TextField>
            </FormControl>
           

            <FormControlLabel
                value="start"
                control={<Checkbox />}
                label="Is Active"
                labelPlacement="start"
            />

            <FormControl required variant="outlined" margin="normal">
                <Button type="submit" variant="outlined">
                    Add Users
                </Button>
            </FormControl>

            <FormControl>
                <Button className="clearButton" variant="contained" color="secondary">
                    Clear
                </Button>
            </FormControl>
        </Box>
    );
};

export default Addregions