import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Avatar, Box, Breadcrumbs, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import regionsData from '../regionsData.json';
import { useDashboardContext } from '../sideDrawer';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


export interface User {
    id: number;
    regionname: string;
 
  
}

interface UserTableProps {
    users: User[];
}

export const Regions: React.FC = () => {

    const { pageTitle, updateContext } = useDashboardContext();
    var oems: any[] = [];

    useEffect(() => {
        updateContext({ pageTitle: "OEM" });
        const getoems = async () => {
            const data = await fetch("http://localhost:3001/oems", {
              method: "GET"
            });
            const jsonData = await data.json();
            oems = jsonData;
            console.log(oems)
          };
      
          getoems();
    }, []);
    
    const breadcrumbs = [
        <Typography key="1" color="inherit">
            Enterprise Architecture
        </Typography>,
        <Typography key="2" color="text.primary">
            Regions
        </Typography>,
    ];

    return (
        <Box>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Avatar component={Link} to="addregions" sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}>
                <AddIcon />
            </Avatar>
        
            <TableContainer component={Paper}>
            <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>ID</TableCell>
                            <TableCell>Region Name</TableCell>
                            <TableCell>Is Active</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {regionsData.regions.map((region) => (
                            <TableRow key={region.id}>
                                <TableCell>{region.id}</TableCell>
                                <TableCell>{region.name}</TableCell>
                              <TableCell>   
                                   <FormControlLabel
                                        value="start"
                                        control={<Checkbox />}
                                        label=""
                                        labelPlacement="start"
                                    />
                            </TableCell>
                              
                                <TableCell>
                                <Button component={Link} to="Updateregions"><EditIcon/></Button>

                                    <Button component={Link} to="addoem"><DeleteIcon/></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
             
            </TableContainer>
        </Box>
    )
}
export default Regions