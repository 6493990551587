import { Box, Button, Checkbox, FormControl, FormControlLabel, Snackbar, TextField } from "@mui/material";
import React, { useState } from "react";

interface Oem {
  name: string;
  logo_url: string;
  status: boolean;
  diads: number[];
}

interface TestAPIResponse {
  success: string;
}

const Addoem: React.FC = () => {
  const [name, setname] = useState("");
  const [logo_url, setLogoUrl] = useState("");
  const [diads, setDiads] = useState<number[]>([]);
  const [nameError, setnameError] = useState(false);
  const [logoUrlError, setLogoUrlError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setnameError(false);
    setLogoUrlError(false);

    if (name === "") {
      setnameError(true);
    }
    if (logo_url === "") {
      setLogoUrlError(true);
    }
    if (name && logo_url) {
      try {
        let OemVar: Oem = {
          name: name,
          logo_url: logo_url,
          status: true,
          diads: diads,
        };

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(OemVar),
        });

        if (response.ok) {
          const responseData: TestAPIResponse = await response.json();
          console.log("RESP:", responseData);
          setSnackbarOpen(true);
        } else {
          console.error("Error while making POST request:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error while making POST request:", error);
      }
    }
  };

  const handleClear = () => {
    setname("");
    setLogoUrl("");
    setDiads([]);
    setnameError(false);
    setLogoUrlError(false);
  };

  return (
    <Box
      display="grid"
      justifyContent="center"
      alignItems="center"
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <FormControl required variant="outlined" margin="normal">
        <TextField
          required
          id="name"
          label="User Name"
          variant="outlined"
          onChange={(e) => setname(e.target.value)}
          value={name}
          error={nameError}
        />
      </FormControl>
      <FormControl required variant="outlined" margin="normal">
        <TextField
          required
          id="logo_url"
          label="Logo URL"
          variant="outlined"
          onChange={(e) => setLogoUrl(e.target.value)}
          value={logo_url}
          error={logoUrlError}
        />
      </FormControl>
      <FormControl required variant="outlined" margin="normal">
        <TextField
          required
          id="diads"
          label="Diads"
          variant="outlined"
       
        />
      </FormControl>
      <FormControlLabel
        value="start"
        control={<Checkbox />}
        label="Is Active"
        labelPlacement="start"
      />
      <FormControl required variant="outlined" margin="normal">
        <Button type="submit" variant="outlined">
          Add Oem
        </Button>
      </FormControl>
      <FormControl>
        <Button
          className="clearButton"
          variant="contained"
          color="secondary"
          onClick={handleClear}
        >
          Clear
        </Button>
      </FormControl>
     
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} 
        onClose={handleSnackbarClose}
        message="Data updated successfully!"
      />
    </Box>
  );
};

export default Addoem;
