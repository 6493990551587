import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Avatar, Box, Breadcrumbs, Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDashboardContext } from '../sideDrawer';
import workshopgroupData from '../workshopgroupData.json';

export interface User {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    mobilenumber :number;
    password: string;
    reports:string;
    created:number;
}

interface UserTableProps {
    users: User[];
}

export const Workshopgroup: React.FC = () => {

    const { pageTitle, updateContext } = useDashboardContext();
    useEffect(() => {
        updateContext({ pageTitle: "Workshop group" })
    }, []);

    const breadcrumbs = [
        <Typography key="1" color="inherit">
            Enterprise Architecture
        </Typography>,
        <Typography key="2" color="text.primary">
            Workshop Group
        </Typography>,
    ];

    return (
        <Box>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Avatar component={Link} to="addworkshopgroup" sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}>
                <AddIcon />
            </Avatar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>region</TableCell>
                            <TableCell>Total workshop</TableCell>
                            <TableCell>Created-On</TableCell>
                            <TableCell>Is Active</TableCell>
                           
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workshopgroupData.workshopgroup.map((workshopgroup) => (
                            <TableRow key={workshopgroup.id}>
                                <TableCell>{workshopgroup.id}</TableCell>
                                <TableCell>{workshopgroup.name}</TableCell>
                                <TableCell>{workshopgroup.region}</TableCell>
                                <TableCell>{workshopgroup.totalworkshop}</TableCell>
                                <TableCell>{workshopgroup['created-on']}</TableCell>
                                <TableCell>   
                                   <FormControlLabel
                                        value="start"
                                        control={<Checkbox />}
                                        label=""
                                        labelPlacement="start"
                                    />
                            </TableCell>
                                
                                <TableCell>
                                    <Button component={Link} to="Updateworkshopgroup"><EditIcon/></Button>
                                    <Button component={Link} to="addsuboem"><DeleteIcon/></Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}