import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from "@mui/material";
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface Oem {
    name: string;
    logo_url: string;
    status: boolean;
    diads: number[];
}

interface TestAPIResponse {
    success: string;
}

const Updateoem: React.FC = () => {
    const navigate = useNavigate(); 
    const { id } = useParams<{ id: string }>();
    const [name, setname] = useState("");
    const [logo_url, setLogoUrl] = useState("");
    const [diads, setDiads] = useState<number[]>([]);

    const [nameError, setnameError] = useState(false);
    const [logoUrlError, setLogoUrlError] = useState(false);

    useEffect(() => {
        axios.get<Oem>(`${process.env.REACT_APP_API_BASE_URL}/oems/${id}`)
            .then((resp: AxiosResponse<Oem>) => {
                const oemDetails: Oem = resp.data;
                setname(oemDetails.name);
                setLogoUrl(oemDetails.logo_url);
            })
            .catch((error) => {
                console.error("Error while fetching details:", error);
            });
    }, [id]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setnameError(false);
        setLogoUrlError(false);

        if (name === "") {
            setnameError(true);
        }
        if (logo_url === "") {
            setLogoUrlError(true);
        }
        if (name && logo_url) {
            axios.put<TestAPIResponse>(`${process.env.REACT_APP_API_BASE_URL}/oems/${id}`, { name, logo_url })
                .then((resp: AxiosResponse<TestAPIResponse>) => {
                    let d: TestAPIResponse = resp.data;
                    console.log("RESP: ", d);
                })
                .catch((error) => {
                    console.error("Error while making request:", error);
                });
        }
    }

    const handleBack = () => {
        navigate("../"); 
    };

    return (
        <Box
            display="grid"
            justifyContent="center"
            alignItems="center"
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <FormControl>
                <Button variant="outlined" onClick={handleBack}>
                    Back
                </Button>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="username"
                    label="User Name"
                    variant="outlined"
                    onChange={(e) => setname(e.target.value)}
                    value={name}
                    error={nameError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="logo_url"
                    label="Logo URL"
                    variant="outlined"
                    onChange={(e) => setLogoUrl(e.target.value)}
                    value={logo_url}
                    error={logoUrlError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
        <TextField
          required
          id="diads"
          label="Diads"
          variant="outlined"
          onChange={(e) => setDiads(e.target.value.split(',').map(Number))}
          value={diads.join(',')}
        />
      </FormControl>

            <FormControlLabel
                value="start"
                control={<Checkbox />}
                label="Is Active"
                labelPlacement="start"
            />

            <FormControl required variant="outlined" margin="normal">
                <Button type="submit" variant="outlined">
                    Update Oem
                </Button>
            </FormControl>

            <FormControl>
                <Button className="clearButton" variant="contained" color="secondary">
                    Clear
                </Button>
            </FormControl>
        </Box>
    );
};

export default Updateoem;
