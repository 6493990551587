import { Navigate, createBrowserRouter } from "react-router-dom";
import Dashboard from "./component/dashboard";

import App from "./App";
import Addoem from "./component/oem/addoempage";
import { Oem } from "./component/oem/oem";
import { OemBase } from "./component/oem/oem_base";

import AddDevice from "./component/device/adddevice";
import { Device } from "./component/device/device";
import { DeviceBase } from "./component/device/device_base";
import UpdateDevice from "./component/device/updatedevice";
import AddDongal from "./component/dongal/adddongal";
import { Dongal } from "./component/dongal/dongal";
import { DongalBase } from "./component/dongal/dongal_base";
import UpdateDongal from "./component/dongal/updatedongal";
import Login from "./component/frontpage/loginpage";
import Updateoem from "./component/oem/updateoem";
import Addregions from "./component/regions/addregions";
import { RegionsBase } from "./component/regions/region_base";
import Regions from "./component/regions/regions";
import Updateregions from "./component/regions/updateregions";
import AddSuboem from "./component/suboem/addsuboempage";
import { SubOem } from "./component/suboem/suboem";
import { SubOemBase } from "./component/suboem/suboem_base";
import UpdateSuboem from "./component/suboem/updatesuboem";
import AddUsers from "./component/users/adduser";
import UpdateUser from "./component/users/updateuser";
import { Users } from "./component/users/users";
import { UserBase } from "./component/users/usersbase";
// import AddVehiclemodel from "./component/vehiclemodel/addvehiclemodel";
import AddEcu from "./component/ecu/addecu";
import { Ecu } from "./component/ecu/ecu";
import { EcuBase } from "./component/ecu/ecu_base";
import UpdateEcu from "./component/ecu/updateecu";
import { Submodel } from "./component/submodel/submodel";
import UpdateSubmodel from "./component/submodel/updatesubmodel";
import AddVehiclemodel from "./component/vehiclemodel/addvehiclemodel";
import { Vehiclemodel } from "./component/vehiclemodel/vehiclemodel";
import { VehiclemodelsBase } from "./component/vehiclemodel/vehiclemodel_base";
import AddWorkshop from "./component/workshop/addworkshop";
import UpdateWorkshop from "./component/workshop/updateworkshop";
import { Workshop } from "./component/workshop/workshop";
import { WorkshopBase } from "./component/workshop/workshop_base";
import Addworkshopgroup from "./component/workshopgroup/addworkshop";
import Updateworkshopgroup from "./component/workshopgroup/updateworkshopgroup";
import { Workshopgroup } from "./component/workshopgroup/workshopgroup";


const router = createBrowserRouter([
	{
        path: '',
        children: [{
            index: true,
            element: <Navigate to='login'/>
        },
        {
            path: "login",
            element: <Login/>
        }
        ]
     
    },
    {
		path: 'auth',
		element: <App/>,
        children: [
            {
                index: true,
                element: <Navigate to='dashboard' />
            },
           
       
            {
                path: 'dashboard',
                element: <Dashboard/>
            },
            {
                path: 'oem',
                element: <OemBase />,
                children: [ 
                    {
                        index: true,
                        element: <Oem/>
                    },
                    {
                        path: 'addoem',
                        element: <Addoem/>
                    },
                    {
                        path : 'updateoem/:id',
                        element : <Updateoem/>
                    }
                ]
            },
            {
                path: 'suboem',
                element: <SubOemBase />,
                children: [ 
                    {
                        index: true,
                        element: <SubOem/>
                    },
                    {
                        path: 'addsuboem',
                        element: <AddSuboem/>
                    },
                
               
                    {
                        path : 'updatesuboem',
                        element : <UpdateSuboem/>
                    }
                ]
            },
            {
                path: 'regions',
                element: <RegionsBase />,
                children: [ 
                    {
                        index: true,
                        element: <Regions/>
                    },
                    {
                        path : "addregions",
                        element :<Addregions/>
                    },
                    {
                        path : "updateregions",
                        element :<Updateregions/>
                    }
                   
                ]
            },
            {
                path: 'workshopgroup',
                element: <WorkshopBase/>,
                children: [ 
                    {
                        index: true,
                        element: <Workshopgroup/>
                    },
                    {
                        path : 'addworkshopgroup',
                        element : <Addworkshopgroup/>
                    },
                    {
                        path : 'updateworkshopgroup',
                        element : <Updateworkshopgroup/>
                    }
                   
                ]
            },
            {
                path :'workshop',
                element : <WorkshopBase/>,
                children: [
                    {
                        index : true,
                        element :<Workshop/>
                    },
                    {
                        path : 'addworkshop',
                        element : <AddWorkshop/>
                    },
                    {
                        path : 'updateworkshop',
                        element : <UpdateWorkshop/>
                    }

                ]
            },
            {
                path :'users',
                element : <UserBase/>,
                children: [
                    {
                        index : true,
                        element :<Users/>
                    },
                    {
                        path : 'addusers',
                        element : <AddUsers/>
                    },
                    {
                        path : 'updateusers',
                        element : <UpdateUser/>
                    }

                ]
            },
            {
                path :'device',
                element : <DeviceBase/>,
                children : [
                    {
                        index : true ,
                        element :<Device/>
                    },
                    {
                        path :'adddevice',
                        element :<AddDevice/>
                    },
                    {
                        path :'updatedevice',
                        element :<UpdateDevice/>
                    }

                ]
            },
            {
                path :'dongal',
                element : <DongalBase/>,
                children : [
                    {
                        index : true ,
                        element :<Dongal/>
                    },
                    {
                        path :'adddongal',
                        element :<AddDongal/>
                    },
                    {
                        path :'updatedongal',
                        element :<UpdateDongal/>
                    }

                ]
            }, 
             {
                path :'vehiclemodel',
                element : <VehiclemodelsBase/>,
                children : [
                    {
                        index : true ,
                        element :<Vehiclemodel/>
                    },
                    {
                        path :'addvehiclemodel',
                        element :<AddVehiclemodel/>
                    },
                    {
                        path :'updatedongal',
                        element :<UpdateDongal/>
                    }

                ]
            },
            {
                path :'ecu',
                element : <EcuBase/>,
                children : [
                    {
                        index : true ,
                        element :<Ecu/>
                    },
                    {
                        path :'addecu',
                        element :<AddEcu/>
                    },
                    {
                        path :'updateecu',
                        element :<UpdateEcu/>
                    }

                ]
            },
            {
                path :'submodel',
                element : <SubOemBase/>,
                children : [
                    {
                        index : true ,
                        element :<Submodel/>
                    },
                    {
                        path :'addsubmodel',
                        element :<AddSuboem/>
                    },
                    {
                        path :'updatesubmodel',
                        element :<UpdateSubmodel/>
                    }

                ]
            }
        ]
        }
]
	
);

export default router;
// git init

