
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Avatar, Box, Breadcrumbs, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDashboardContext } from '../sideDrawer';
import submodelData from '../submodelData.json';

export interface User {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    age: number;
    logo: File | null;
}

interface UserTableProps {
    users: User[];
}

export const Submodel: React.FC = () => {

    const { pageTitle, updateContext } = useDashboardContext();
    var oems: any[] = [];

    useEffect(() => {
        updateContext({ pageTitle: "OEM" });
        const getoems = async () => {
            const data = await fetch("http://localhost:3001/oems", {
              method: "GET"
            });
            const jsonData = await data.json();
            oems = jsonData;
            console.log(oems)
          };
      
          getoems();
    }, []);
    
    const breadcrumbs = [
        <Typography key="1" color="inherit">
            Enterprise Architecture
        </Typography>,
        <Typography key="2" color="text.primary">
            ECU
        </Typography>,
    ];

    return (
        <Box>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Avatar component={Link} to="addecu" sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}>
                <AddIcon />
            </Avatar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Feature</TableCell>
                            <TableCell>XYZ Details</TableCell>
                            <TableCell>Color</TableCell>
                            <TableCell>Attachment</TableCell>
                            <TableCell>Created-on</TableCell>
                            <TableCell>Is Active</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {submodelData.submodels.map((submodel) => (
                            <TableRow key={submodel.id}>
                                <TableCell>{submodel.id}</TableCell>
                                <TableCell>{submodel.name}</TableCell>
                                <TableCell>{submodel.Description}</TableCell> 
                                <TableCell>{submodel.Feature}</TableCell> 
                                <TableCell>{submodel['XYZ Details']}</TableCell>
                                <TableCell>{submodel.Color}</TableCell>
                                <TableCell>{submodel.Attachment}</TableCell>
                                <TableCell>{submodel.created}</TableCell>
                                <TableCell>
                                <Button component={Link} to="Updatesubmodel"><EditIcon/></Button>

                                    <Button component={Link} to="addsubmodel"><DeleteIcon/></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
        </Box>
    )
}

