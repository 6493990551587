
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Avatar, Box, Breadcrumbs, Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDashboardContext } from '../sideDrawer';
import suboemData from '../suboemData.json';



export interface User {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    mobilenumber :number;
    password: string;
    reports:string;
    created:number;
}

interface UserTableProps {
    users: User[];
}

export const SubOem: React.FC = () => {

    const { pageTitle, updateContext } = useDashboardContext();
    useEffect(() => {
        updateContext({ pageTitle: "Sub-OEM" })
    }, []);

    const breadcrumbs = [
        <Typography key="1" color="inherit">
            Enterprise Architecture
        </Typography>,
        <Typography key="2" color="text.primary">
            SubOEM
        </Typography>,
    ];

    return (
        <Box>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Avatar component={Link} to="addsuboem" sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}>
                <AddIcon />
            </Avatar>
            <TableContainer component={Paper}>
                <Table>
                 
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Is Active</TableCell>
                            <TableCell>Created-on</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {suboemData.suboems.map((suboem) => (
                            <TableRow key={suboem.id}>
                                <TableCell>{suboem.id}</TableCell>
                                <TableCell>{suboem.name}</TableCell>
                                <TableCell>   
                                   <FormControlLabel
                                        value="start"
                                        control={<Checkbox />}
                                        label=""
                                        labelPlacement="start"
                                    />
                            </TableCell>
                                
                                <TableCell>{suboem['Created_on']}</TableCell>
                                <TableCell>
                                <Button component={Link} to="Updatesuboem"><EditIcon/></Button>

                                    <Button component={Link} to="addoem"><DeleteIcon/></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
// hello