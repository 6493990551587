
import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField, Typography } from "@mui/material";
import axios, { AxiosResponse } from 'axios';
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { User } from "./device";



interface TestAPIResponse {
    success: string;
}
const breadcrumbs = [
    <Typography key="1" color="inherit">
        Devices
    </Typography>,
    <Typography key="2" color="text.primary">
        Add Devices
    </Typography>,
];
const AddDevice: React.FC = () => {
    const [DeviceAddress, setDeviceAdderss] = useState("");
    const [DeviceType, setDeviceType] = useState("");
    const [CreatedBy, setCreatedBy] = useState("");
    const [CreatedOn, setCreatedOn] = useState("");   
 
    const [DeviceAddressError, setDeviceAdderssError] = useState(false);
    const [DeviceTypeError, setDeviceTypeError] = useState(false);
    const [CreatedByError, setCreatedByError] = useState(false);
    const [CreatedOnError, setCreatedOnError] = useState(false);  
    // const [usernameError, setUserNameError] = useState(false);
    // const [firstnameError, setFirstNameError] = useState(false);
    // const [lastnameError, setLastNameError] = useState(false);
    // const [emailError, setEmailError] = useState(false);


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setDeviceAdderssError(false);
        setDeviceTypeError(false);
        setCreatedByError(false);
        setCreatedOnError(false);
       

        if (DeviceAddress === "") {
            setDeviceAdderssError(true);
        }
        if (DeviceType === "") {
            setDeviceTypeError(true);
        }
        if (CreatedBy === "") {
            setCreatedByError(true);
        }
        if (CreatedOn === "") {
            setCreatedOnError(true);
        }
       
        

        if (DeviceAddress && DeviceType && CreatedBy && CreatedOn  ) {
            console.log(DeviceAddress, DeviceType, CreatedBy, CreatedOn);

            let userVar: User = {
                id: 1,
                DeviceAddress: DeviceAddress,
                DeviceType: DeviceType,
                CreatedBy: CreatedBy,
                CreatedOn: 0,
             


            };

            axios.post<TestAPIResponse>("https://enhtxu896gu1.x.pipedream.net/", userVar).then(
                (resp: AxiosResponse<TestAPIResponse>) => {
                    let d: TestAPIResponse = resp.data;
                    console.log("RESP: ", d);
                }
            );
        }
    };

    return (
        <Box
            display="grid"
            justifyContent="center"
            alignItems="center"
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
             <FormControl>
                <Button variant="outlined"
                  component = {RouterLink} to= "../">
                    Back
                </Button>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="username"
                    label="Device Address"
                    variant="outlined"
                    onChange={(e) => setDeviceAdderss(e.target.value)}
                    value={DeviceAddress}
                    error={DeviceAddressError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="firstname"
                    label="Device Type"
                    variant="outlined"
                    onChange={(e) => setDeviceType(e.target.value)}
                    value={DeviceType}
                    error={DeviceTypeError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="lastname"
                    label="Created By"
                    variant="outlined"
                    onChange={(e) => setCreatedBy(e.target.value)}
                    value={CreatedBy}
                    error={CreatedByError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="email"
                    label="Created On"
                    type="email"
                    variant="outlined"
                    onChange={(e) => setCreatedOn(e.target.value)}
                    value={CreatedOn}
                    error={CreatedOnError}
                ></TextField>
            </FormControl>
           
            
            
              
            <FormControlLabel
          value="start"
          control={<Checkbox/>}
          label="Is Active"
          labelPlacement="start"
        />
            <FormControl required variant="outlined" margin="normal">
                <Button type="submit" variant="outlined">
                    Add Device
                </Button>
            </FormControl>
            <FormControl>
                <Button className="clearButton" variant="contained" color="secondary">
                    Clear
                </Button>
            </FormControl>
        </Box>
    );
};

export default AddDevice