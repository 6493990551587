import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Avatar, Box, Breadcrumbs, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDashboardContext } from '../sideDrawer';
import workshopData from '../workshopData.json';

export interface User {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    mobilenumber :number;
    password: string;
    reports:string;
    created:number;
}

interface UserTableProps {
    users: User[];
}

export const Workshop: React.FC = () => {

    const { pageTitle, updateContext } = useDashboardContext();
    useEffect(() => {
        updateContext({ pageTitle: "Workshop" })
    }, []);

    const breadcrumbs = [
        <Typography key="1" color="inherit">
            Enterprise Architecture
        </Typography>,
        <Typography key="2" color="text.primary">
            Workshop
        </Typography>,
    ];

    return (
        <Box>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Avatar component={Link} to="addworkshop" sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}>
                <AddIcon />
            </Avatar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>name</TableCell>
                            <TableCell>region</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Pincode</TableCell>
                            <TableCell>Created-on</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workshopData.workshop.map((workshop) => (
                            <TableRow key={workshop.id}>
                                <TableCell>{workshop.id}</TableCell>
                                <TableCell>{workshop.name}</TableCell>
                                <TableCell>{workshop.region}</TableCell>
                                <TableCell>{workshop.country}</TableCell>
                                <TableCell>{workshop.State}</TableCell>
                                <TableCell>{workshop.city}</TableCell>
                                <TableCell>{workshop.pincode}</TableCell>
                                <TableCell>{workshop.created}</TableCell>

                                <TableCell>
                                    <Button component={Link} to="updateworkshop"><EditIcon/></Button>
                                    <Button component={Link} to="addsuboem"><DeleteIcon/></Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}