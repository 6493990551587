import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from "@mui/material";
import axios, { AxiosResponse } from 'axios';
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";


interface TestAPIResponse {
    success: string;
}

const UpdateEcu: React.FC = () => {
    const [name, setname] = useState("");
    const [Modelyear, setModelyear] = useState("");
    const [Txheader, setTxheader] = useState("");
    const [Fxheader, setFxheader] = useState("");
    const [Created, setCreated] = useState("");
    

    const [nameError, setnameError] = useState(false);
    const [ModelyearError, setModelyearError] = useState(false);
    const [TxheaderError, setTxheaderError] = useState(false);
    const [FxheaderError, setFxheaderError] = useState(false);
    const [createdError, setcreatedError] = useState(false)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setnameError(false);
        setModelyearError(false);
        setTxheaderError(false);
        setFxheaderError(false);
        setcreatedError(false);
       

        if (name === "") {
            setnameError(true);
        }
        if (Modelyear === "") {
            setModelyearError(true);
        }
        if (Txheader === "") {
            setTxheaderError(true);
        }
        if (Fxheader === "") {
            setFxheaderError(true);
        }
        if (Created === "") {
            setcreatedError(true);
        }
      
        if (name && Modelyear && Txheader && Fxheader && Created  ) {
            console.log(name, Modelyear, Txheader, Fxheader, Created, );

         
            axios.post<TestAPIResponse>("https://enhtxu896gu1.x.pipedream.net/").then(
                (resp: AxiosResponse<TestAPIResponse>) => {
                    let d: TestAPIResponse = resp.data;
                    console.log("RESP: ", d);
                }
            );
        }
    };

    return (
        <Box
            display="grid"
            justifyContent="center"
            alignItems="center"
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
                 <FormControl>
                <Button variant="outlined"
                  component = {RouterLink} to= "../">
                    Back
                </Button>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="username"
                    label="name"
                    variant="outlined"
                    onChange={(e) => setname(e.target.value)}
                    value={name}
                    error={nameError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="firstname"
                    label="Model Year"
                    variant="outlined"
                    onChange={(e) => setModelyear(e.target.value)}
                    value={Modelyear}
                    error={ModelyearError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="lastname"
                    label="Tx-Header"
                    variant="outlined"
                    onChange={(e) => setTxheader(e.target.value)}
                    value={Txheader}
                    error={TxheaderError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="email"
                    label="Fx-Header"
                    type="email"
                    variant="outlined"
                    onChange={(e) => setFxheader(e.target.value)}
                    value={Fxheader}
                    error={FxheaderError}
                ></TextField>
            </FormControl>
            <FormControl required variant="outlined" margin="normal">
                <TextField
                    required
                    id="age"
                    label="Created-on"
                    type="number"
                    variant="outlined"
                    onChange={(e) => setCreated(e.target.value)}
                    value={Created}
                    error={createdError}
                ></TextField>
            </FormControl>
            
            
              
            <FormControlLabel
          value="start"
          control={<Checkbox />}
          label="Is Active"
          labelPlacement="start"
        />
            <FormControl required variant="outlined" margin="normal">
                <Button type="submit" variant="outlined">
                    Update ECU
                </Button>
            </FormControl>
            <FormControl>
                <Button className="clearButton" variant="contained" color="secondary">
                    Clear
                </Button>
            </FormControl>
        </Box>
    );
};

export default UpdateEcu